.formio-slider-component {
  .field-required {
    display: none !important;
  }
  position: relative;
  .input-suffix,
  input:not(.formio-range-slider) {
      text-align: right;
      padding-right: 7px !important;
      font-weight: 600;
      color: var(--calculator-input-color)!important;
      font-size: 21px !important;
  }

  .formio-range-slider {
      height: 4px;
      position: absolute;
      bottom: -4px;
      width: 100%;
      cursor: pointer;
  }

  &:has(.is-invalid) {
    .formio-range-slider {
      bottom: 20px;
    }
  }

  .form-control {
    background-image: none;
  }

  .form-control:active, .form-control:focus:not(.is-invalid) {
      box-shadow: none;
      border-color: none;
  }

  .input-group > .form-control:focus, .input-group > .custom-select:focus, .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
      z-index: 0;
  }

  .form-control:active,
  .form-control:focus,
  .form-control:hover {
      border-color: var(--input-border-color-hover) !important;

      &+.input-group-append {
          border-color: var(--input-border-color-hover) !important;
      }
  }

  .input-group:has(.input-group-prepend) {
      .form-control {
          border-left: none;
      }
  }

  .input-group:has(.form-control:active,.form-control:focus,.form-control:hover) {
    .input-group-prepend {
      .input-group-text {
        transition: all ease 0.2s;
        border: 1px solid var(--input-border-color-hover) !important;
      }
    }
  }

  .input-group:has(.is-invalid) {
    .input-group-prepend {
      .input-group-text {
        transition: all ease 0.2s;
        border: 1px solid #f73743 !important;
      }
    }
  }
}

input[type="range"] {
  -webkit-appearance: none;
  border-radius: 5px;
  background-image: linear-gradient(var(--calculator-slider-track), var(--calculator-slider-track));
  background-repeat: no-repeat;
  background-size: 0 0;
}

/* Input Thumb */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 1px solid var(--calculator-slider-handle-border);
  background: var(--calculator-slider-handle-bg);
}

input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 1px solid var(--calculator-slider-handle-border);
  background: var(--calculator-slider-handle-bg);
}

input[type="range"]::-ms-thumb {
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 1px solid var(--calculator-slider-handle-border);
  background: var(--calculator-slider-handle-bg);
}

/* Input Track */
input[type=range]::-webkit-slider-runnable-track  {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

input[type=range]::-moz-range-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

input[type="range"]::-ms-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}